import {
  tns
} from 'tiny-slider/src/tiny-slider';
import {
  Controller
}
from "@hotwired/stimulus"



export default class extends Controller {
  static targets = ['container']

  connect() {
    // console.log('Hello from Slider');
    this.initSlider();
  }

  initSlider() {
    this.slider = tns({
      container: this.containerTarget,
      loop: false,
      startIndex: 1,
      items: 1,
      nav: false,
      autoplay: true,
      swipeAngle: false,
      speed: 400,
      autoplayButtonOutput: false,
      mouseDrag: true,
      lazyload: true,
      gutter: 20,
      controlsContainer: this.containerTarget, //was "sliderFirstControls",
      responsive: {
        768: {
          items: 2
        },

        990: {
          items: 4
        }
      }
    });
  }

  next() {
    this.slider.goTo('next');
  }

  prev() {
    this.slider.goTo('prev');
  }
}


// old
      // container: this.containerTarget,
      //   items: 3,
      //   slideBy: 'page',
      //   // slideBy: 1,
      //   // gutter: 10,
      //   // mouseDrag: true,
      //   // arrowKeys: true,
      //   // controls: false,
      //   // nav: false,
      //   autoplay: true
