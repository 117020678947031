
window.addEventListener("turbolinks:load", event => {
//  $(document).on("turbolinks:load", () => {



// Wizard START
const progress = (value) => {
  document.getElementsByClassName('progress-bar')[0].style.width = `${value}%`;
}

let step = document.getElementsByClassName('step');
// console.log(step.length)

//test if the wizard exists on the page
if (step.length != 0 && document.getElementById('next-btn') != undefined) {
    let prevBtn = document.getElementById('prev-btn');
    let nextBtn = document.getElementById('next-btn');
    let submitBtn = document.getElementById('submit-btn');
    let form = document.getElementsByTagName('form')[0];
    let preloader = document.getElementById('preloader-wrapper');
    let bodyElement = document.querySelector('body');
    let succcessDiv = document.getElementById('success');

    form.onsubmit = () => {
      return false
    }

    // let seller = 0;

    let current_step = 0;
    let stepCount = 5;

    step[current_step].classList.add('d-block');
    // let stepCountSeller = 4;
    if (current_step == 0) {
      prevBtn.classList.add('d-none');
      submitBtn.classList.add('d-none');
      nextBtn.classList.add('d-inline-block');
    }


    nextBtn.addEventListener('click', () => {

      seller=false;
      seller = document.getElementById('q_1_no').checked;
    // if true it's the seller, if false it's a buyer
    // console.log(seller);

    (document.getElementById("label-phone").innerHTML = (seller == true )? "Telefonní číslo" : "Telefonní číslo - jak vás mohou zákazníci kontaktovat?");
    (document.getElementById("label-zip").innerHTML = (seller == true) ? "PSČ" : "PSČ kde jsi nejčastěji - jak vás mohou zákazníci najít?");
    (document.getElementById("label-about").innerHTML = (seller == true) ? "S čím potřebujete výpomoc / více o požadované práci" : "O mně. Napiš něco o sobě, jaké máš zkušenosti v oboru (např. uklízením)");
    (document.getElementById("label-whyme").innerHTML = (seller == true) ? "Specifikace místa výkonu práce" : "Podrobnosti o práci - Proč by si vás měli vybrat?");
    (document.getElementById("label-whyme2").innerHTML = (seller == true) ? "Místo výkonu práce (např. byt, dům, kancelář atd.)" : "Proč by si vás měli vybrat? Jednou větou o sobě.");
    (document.getElementById("label-pay").innerHTML = (seller == true) ? " Max. cena v Kč (kolik jste ochotni maximálně zaplatit za hodinu)" : "Cena v Kč za hodinu");

    (document.getElementById("label-final1").innerHTML = (seller == true) ? "Máte možnost vložit profilovou fotografii, která vám pomůže získat větší důvěru pomocníků (není podmínkou registrace)" : "V profilu nezapomeňte nahrát svoji fotografii. Fotografie je potřeba pro zobrazení vašeho profilu ostatním.");
    (document.getElementById("label-final2").innerHTML = (seller == true) ? "Máte možnost ověřit vaši identitu a tím získat větší bůvěru pomocníků (váš profil se bude zobrazovat jako ověřený)" : " Nezapomeňte ověřit vaši identitu. Pomůže vám to získat více nabídek.");



    (document.getElementById("name").style.borderColor = (document.getElementById("name").value == '') ? "red" : "gainsboro");
    (document.getElementById("phone").style.borderColor = (document.getElementById("phone").value == '') ? "red" : "gainsboro");
    (document.getElementById("zip").style.borderColor = (document.getElementById("zip").value == '') ? "red" : "gainsboro");
    (document.getElementById("about").style.borderColor = (document.getElementById("about").value == '') ? "red" : "gainsboro");
    (document.getElementById("why_me").style.borderColor = (document.getElementById("why_me").value == '') ? "red" : "gainsboro");
    (document.getElementById("price").style.borderColor = (document.getElementById("price").value == 0) ? "red" : "gainsboro");
    (document.getElementById("age").style.borderColor = (document.getElementById("age").value == '') ? "red" : "gainsboro");

      current_step++;
      let previous_step = current_step - 1;
      if ((current_step > 0) && (current_step <= stepCount)) {
        prevBtn.classList.remove('d-none');
        prevBtn.classList.add('d-inline-block');
        step[current_step].classList.remove('d-none');
        step[current_step].classList.add('d-block');
        step[previous_step].classList.remove('d-block');
        step[previous_step].classList.add('d-none');
        if (current_step == stepCount) {
          submitBtn.classList.remove('d-none');
          submitBtn.classList.add('d-inline-block');
          nextBtn.classList.remove('d-inline-block');
          nextBtn.classList.add('d-none');
        }
      } else {
        if (current_step > stepCount) {
          form.onsubmit = () => {
            return true
          }
        }
      }
      progress((100 / stepCount) * current_step);
    });


    prevBtn.addEventListener('click', () => {
      if (current_step > 0) {
        current_step--;
        let previous_step = current_step + 1;
        prevBtn.classList.add('d-none');
        prevBtn.classList.add('d-inline-block');
        step[current_step].classList.remove('d-none');
        step[current_step].classList.add('d-block')
        step[previous_step].classList.remove('d-block');
        step[previous_step].classList.add('d-none');
        if (current_step < stepCount) {
          submitBtn.classList.remove('d-inline-block');
          submitBtn.classList.add('d-none');
          nextBtn.classList.remove('d-none');
          nextBtn.classList.add('d-inline-block');
          prevBtn.classList.remove('d-none');
          prevBtn.classList.add('d-inline-block');
        }
      }

      if (current_step == 0) {
        prevBtn.classList.remove('d-inline-block');
        prevBtn.classList.add('d-none');
      }
      progress((100 / stepCount) * current_step);
    });


    submitBtn.addEventListener('click', () => {
      preloader.classList.add('d-block');

      const timer = ms => new Promise(res => setTimeout(res, ms));

      timer(3000)
        .then(() => {
          bodyElement.classList.add('loaded');
        }).then(() => {
          step[stepCount].classList.remove('d-block');
          step[stepCount].classList.add('d-none');
          prevBtn.classList.remove('d-inline-block');
          prevBtn.classList.add('d-none');
          submitBtn.classList.remove('d-inline-block');
          submitBtn.classList.add('d-none');
          succcessDiv.classList.remove('d-none');
          succcessDiv.classList.add('d-block');
        })

    });
}



// Wizard END


});
// note: import  end
