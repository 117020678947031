// note: I probably have to copy all the java script here because it doesn't work anywhere else except this file


import AOS from 'aos';
// import AOS begin


document.addEventListener('DOMContentLoaded', function () {
  AOS.init({
    startEvent: 'turbolinks:load' // if you are using turbolinks
  });
});

window.addEventListener('load', AOS.refresh)
// import AOS end

//  this is for mobile menu - begin
document.addEventListener('turbolinks:load', function () {
  $('.drawer').drawer();
});
//  this is for mobile menu - end

//  this is for mobile menu - begin
document.addEventListener('turbolinks:load', function () {

  $('#check1').on('click', function () {
    if ($(':checked').length >= 2) {
      this.checked ? $('.item1-change').css('border', '2px solid #d0b089 ') : $('.item1-change').css('border', '2px solid white');
    }
    calc_total();
  });

  $('#check2').on('click', function () {
    if ($(':checked').length >= 2) {
      this.checked ? $('.item2-change').css('background-color', '#fdf9e7') : $('.item1-change').css('background-color', 'white');
    }
    calc_total();
  });


  $('#check3').on('click', function () {
    if ($(':checked').length >= 2) {
      this.checked ? $('.item3-change').css('display', 'block') : $('.item3-change').css('display', 'none');
    }
    calc_total();
  });


function calc_total() {
  //check all the check boxes and calc the total - start
  var tot = 0;
  var v1,v2,v3,url;
  if ($('#check1').is(':checked')) {v1=1; tot += 99}else{v1=0}
  if ($('#check2').is(':checked')) {v2=1; tot += 299}else{v2=0}
  if ($('#check3').is(':checked')) {v3=1; tot += 499}else{v3=0}
  $('.pimp_ad_total').html("Celkem: " + tot + " Kč");
  //check all the check boxes and calc the total - end
  if (v1+v2+v3>0) { url='/products/2' + v1 + v2 + v3 } else { url=""}
$('#pimp_ad_form').attr('action', url );
}

});
//  this is for mobile menu - end



//  this is for all select pickers - begin
document.addEventListener('turbolinks:load', function () {
  // $('select').selectpicker();
  // console.log($('.selectpicker').length);
  if (!$('.bootstrap-select').length) {
    $('.selectpicker').selectpicker();
  }
});
//  this is for all select pickers - end
