// IMPORT / REQUIRE JS
require("jquery")


require("@rails/activestorage").start()


//require("@rails/ujs").start()
import Rails from '@rails/ujs';
Rails.start();

require("turbolinks").start()

require("bootstrap")


// note: STIMULUS code
import "controllers" //this is where my stimulus code is


// IMPORT CUSTOM JS
// note: this is where I put my JS pack code.
require("packs/custom")
require("packs/wizard")

require ("bootstrap-select")
require('tiny-slider')
require('magnific-popup')
require('jquery-drawer');



require ("jquery-slimscroll"); //this is needed for main.js


import inputmask from "inputmask"
window.inputmask = inputmask;

import flatpickr from "flatpickr"
window.flatpickr = flatpickr;

// This is a text editor --- https://quilljs.com/
// import quill from "quill"
// window.Quill = quill;

import tippy from 'tippy.js'
window.tippy = tippy;


import ApexCharts from "apexcharts"
window.ApexCharts = ApexCharts;









// import dragula from "dragula"
// require ("dragula"); // there's an error somewhere - dragulla is too big... Probably I won't use it

// import("js/main") //dont know if I need it


import("js/theme.min"); // without it I can't make the menu work on the phone / ipad


// IMPORT custom CSS in the pack
// note: even though I created this file I import all CSS files in a different file: assets/stylesheets/application.scss
// import "css/site.scss" //this is a custom file I created - by eu @ app/javascript/css/site.scss

